<template>
    <div>
        <div v-if="page_loading">
            <v-dialog v-model="page_loading" hide-overlay persistent>
                <div class="text-center">
                    <v-progress-circular
                        :size="200"
                        color="error"
                        indeterminate
                    >กำลังโหลด</v-progress-circular>
                </div>
            </v-dialog>
        </div>

        <div v-if="loading">
            <v-dialog v-model="loading" persistent>
                <div class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="error"
                        indeterminate
                    ></v-progress-circular>
                </div>
            </v-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'page_loading',
        'loading'
    ],
    setup(props) {
        props.page_loading=false;
        props.loading=false;
      
    }
    
}
</script>

<style scoped>
    >>>.v-dialog {
        overflow-y: hidden;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
</style>